import React, { useState, useCallback, useEffect } from "react";
import "../Profile/index.less";
import { effect, useStore } from "../../../utils/dva16";
import { NLogin, EGet, MNotes } from "../../../models/contants";
import { Helmet } from "react-helmet";
import { customizeRenderEmpty } from "../../../utils/utils";
import { Divider } from "antd";

export default (props) => {
	const { currentSubscribe, userInfo } = useStore(NLogin);
	const { marketNotes } = useStore(MNotes);

	useEffect(() => {
		effect(NLogin, EGet, {});
		effect(MNotes, EGet);
	}, []);
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				flexDirection: "column",
			}}
		>
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			{userInfo?.type == "Client" ? (
				currentSubscribe?.module?.hasOwnProperty("MarketModel") &&
				currentSubscribe?.module["MarketModel"]?.length > 0 ? (
					currentSubscribe?.module["MarketModel"]?.length === 1 &&
					currentSubscribe?.module["MarketModel"]?.includes("China") ? (
						<iframe
							title="Market Model - China"
							style={{ width: "100%", height: "100vh", margin: "20px" }}
							src="https://app.powerbi.com/view?r=eyJrIjoiOWQwMWEyNmYtMzIwZC00YjRmLTllM2QtMDc3OGY0ZWNmZmE4IiwidCI6IjQ5NDkyZjUwLTk2NTEtNGYyNC1hYjFkLWMzNmQ0NzRlNWZmMyIsImMiOjZ9"
							frameborder="0"
							allowFullScreen="true"
						></iframe>
					) : currentSubscribe?.module["MarketModel"]?.length === 2 &&
					  currentSubscribe?.module["MarketModel"]?.includes("China") &&
					  currentSubscribe?.module["MarketModel"]?.includes("East Asia") ? (
						<iframe
							title="Market Model - China + East Asia"
							style={{ width: "100%", height: "100vh", margin: "20px" }}
							src="https://app.powerbi.com/view?r=eyJrIjoiNGUwNGM0ZTAtYWFlYS00MTY2LWE0MGQtMTg1YzY5MzBiYzM5IiwidCI6IjQ5NDkyZjUwLTk2NTEtNGYyNC1hYjFkLWMzNmQ0NzRlNWZmMyIsImMiOjZ9"
							frameborder="0"
							allowFullScreen="true"
						></iframe>
					) : (
						<iframe
							title="Market_Model"
							style={{ width: "100%", height: "100vh", margin: "20px" }}
							src="https://app.powerbi.com/view?r=eyJrIjoiODUzYjgwMTMtMmY5Ni00MzRjLWEwOGQtN2ViYzdlYzkyMjg1IiwidCI6IjQ5NDkyZjUwLTk2NTEtNGYyNC1hYjFkLWMzNmQ0NzRlNWZmMyIsImMiOjZ9"
							frameborder="0"
							allowFullScreen="true"
						></iframe>
					)
				) : (
					customizeRenderEmpty()
				)
			) : (
				<iframe
					title="Market_Model"
					style={{ width: "100%", height: "100vh", margin: "20px" }}
					src="https://app.powerbi.com/view?r=eyJrIjoiODUzYjgwMTMtMmY5Ni00MzRjLWEwOGQtN2ViYzdlYzkyMjg1IiwidCI6IjQ5NDkyZjUwLTk2NTEtNGYyNC1hYjFkLWMzNmQ0NzRlNWZmMyIsImMiOjZ9"
					frameborder="0"
					allowFullScreen="true"
				></iframe>
			)}
			<Divider
				style={{
					borderWidth: "2px",
					borderColor: "rgb(219 218 218)",
					margin: "25px 20px",
				}}
			/>
			<div
				className="marketNotes"
				dangerouslySetInnerHTML={{
					__html: marketNotes && marketNotes[0]?.note,
				}}
				style={{ margin: "0 20px" }}
			/>
		</div>
	);
};
