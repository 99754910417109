import React, { useEffect, useState } from "react";
import { NTab, EGet, NLogin } from "../../../models/contants";
import { effect, useStore } from "../../../utils/dva16";
import MarketModel from "./MarketModel";
import MarketModelTable from "./MarketModelTable";
import Mentain from "../../../utils/mentain.jsx";
import { customizeRenderEmpty } from "@/utils/utils";
import "../../../index.less";
import { Tabs, Empty, Button } from "antd";
const { TabPane } = Tabs;

const MarketTabs = () => {
	const { tabList } = useStore(NTab);
	const [activeTab, setActiveTab] = useState("1");
	const { currentSubscribe, userInfo } = useStore(NLogin);
	useEffect(() => {
		effect(NTab, EGet, { page_name: "market" });
	}, []);

	const onChange = (key) => {
		setActiveTab(key);
	};
	useEffect(() => {
		if ((tabList?.[0]?.bi_tab == 0) & (tabList?.[0]?.table_tab == 1)) {
			setActiveTab("2");
		}
	}, [tabList]);
	const customizeRenderEmptyM = () => <Mentain />;
	console.log(tabList);
	return (
		<>
			{userInfo?.type === "Admin" ||
			(currentSubscribe?.module?.hasOwnProperty("MarketModel") &&
				currentSubscribe?.module["MarketModel"]?.length > 0) ? (
				<>
					<Tabs
						onChange={onChange}
						activeKey={activeTab}
						type="card"
						className="custom-tabs"
					>
						{tabList?.[0]?.bi_tab == 1 && (
							<TabPane tab="Dashboard" key="1">
								<MarketModel />
							</TabPane>
						)}
						{tabList?.[0]?.table_tab == 1 && (
							<TabPane tab="Table" key="2">
								<MarketModelTable />
							</TabPane>
						)}
					</Tabs>
					{tabList?.[0]?.bi_tab != 1 && tabList?.[0]?.table_tab != 1
						? customizeRenderEmptyM()
						: ""}
				</>
			) : (
				customizeRenderEmpty()
			)}
		</>
	);
};

export default MarketTabs;
