import React, { useState, useCallback, useEffect } from "react";
import "../Profile/index.less";
import "./index.less";
import { effect, useStore } from "../../../utils/dva16";
import { NLogin, EGet } from "../../../models/contants";
import { Helmet } from "react-helmet";
import { customizeRenderEmpty } from "../../../utils/utils";

export default (props) => {
	const { currentSubscribe, userInfo } = useStore(NLogin);
	useEffect(() => {
		effect(NLogin, EGet, {});
	}, []);
	return (
		<div style={{ margin: "20px" }}>
			<div
				className="subHeader"
				style={{ color: "#2864A1", marginBottom: "30px", marginLeft: "0px" }}
			>
				Monthly data is sourced from publicly available information provided by
				Shunwang. The data is updated as and when Shunwang releases it. We
				cannot guarantee data for all months.
			</div>

			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			{userInfo?.type == "Client" ? (
				currentSubscribe?.module?.hasOwnProperty("ChinaICafe") &&
				currentSubscribe?.module["ChinaICafe"]?.length > 0 ? (
					<iframe
						title="Internet_Cafe"
						style={{ width: "100%", height: "100vh" }}
						src="https://app.powerbi.com/view?r=eyJrIjoiMTJmZGU0YjktYTczNy00N2NhLTllZTEtNzhlOTIwYjQ0ZTc0IiwidCI6IjQ5NDkyZjUwLTk2NTEtNGYyNC1hYjFkLWMzNmQ0NzRlNWZmMyIsImMiOjZ9"
						frameborder="0"
						allowFullScreen="true"
					></iframe>
				) : (
					customizeRenderEmpty()
				)
			) : (
				<iframe
					title="Internet_Cafe"
					style={{ width: "100%", height: "100vh" }}
					src="https://app.powerbi.com/view?r=eyJrIjoiMTJmZGU0YjktYTczNy00N2NhLTllZTEtNzhlOTIwYjQ0ZTc0IiwidCI6IjQ5NDkyZjUwLTk2NTEtNGYyNC1hYjFkLWMzNmQ0NzRlNWZmMyIsImMiOjZ9"
					frameborder="0"
					allowFullScreen="true"
				></iframe>
			)}
		</div>
	);
};
