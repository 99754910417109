import React from "react";
import notFound from "@/assets/err/icon_404@2x.png";
import { useMediaQuery } from "react-responsive";

const TrendNotFound = (props) => {
	const isSmallScreen = useMediaQuery({ maxWidth: 480 });

	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				minHeight: "300px",
				textAlign: "center",
			}}
		>
			<img
				src={notFound}
				alt="404"
				style={{ maxWidth: "200px", marginBottom: "20px" }}
			/>
			<div style={{ marginLeft: "2rem" }}>
				<h1
					style={{
						fontWeight: 700,
						fontSize: isSmallScreen ? "1.5rem" : "2rem",
						textAlign: "left",
					}}
				>
					No Trending {props?.module == "org" ? "Organizations" : "Games"} for
					This Period
				</h1>
				<p
					style={{
						maxWidth: "50rem",
						textAlign: "left",
						fontSize: isSmallScreen ? "1rem" : "1.5rem",
					}}
				>
					It appears that no{" "}
					{props?.module == "org" ? "organizations" : "games"} experienced a
					significant change in mentions during this period. This could indicate
					a more stable trend environment or a lack of notable activity at this
					time.
				</p>
				<p
					style={{
						maxWidth: "50rem",
						textAlign: "left",
						fontSize: isSmallScreen ? "1rem" : "1.5rem",
					}}
				>
					Stay tuned for updates as we continue monitoring for trends in future
					periods.
				</p>
			</div>
		</div>
	);
};

export default TrendNotFound;
