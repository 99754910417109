import React, { useState, useEffect } from "react";
import moment from "moment";
import "./index.less";
import imgSource from "../../../assets";
import { effect, useStore } from "../../../utils/dva16";
import formatDate from "@/utils/dateFormater";
import {
	EGetDetail,
	EGetTop,
	NInsightsFeed,
	EGet,
	MName,
} from "../../../models/contants";
import { Helmet } from "react-helmet";
import { Button, message } from "antd";

message.config({
	placement: "bottom",
});

export default (props) => {
	const { topNewList } = useStore(NInsightsFeed);
	const { history } = props;
	const {
		location: { state },
	} = history;
	const { InsightDetails } = useStore(NInsightsFeed);
	console.log("InsightDetails", InsightDetails);
	const [topNews, setTopNews] = useState([]);
	const [showAllTags, setShowAllTags] = useState({});
	const [showAllItems, setShowAllItems] = useState(false);

	useEffect(() => {
		//组件渲染时滚动到页面的顶部
		window.scrollTo(0, 0);
	}, []);

	const toggleShowAllItems = () => {
		setShowAllItems((prevShowAll) => !prevShowAll);
	};
	const toggleShowAll = (index) => {
		setShowAllTags((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};
	useEffect(() => {
		effect(NInsightsFeed, EGetDetail, { id: props?.match?.params?.id });

		return () => {};
	}, [props]);
	useEffect(() => {
		effect(NInsightsFeed, EGetTop, { page: 1, pageSize: 10 });
	}, []);
	useEffect(() => {
		if (topNewList) {
			//筛选出不是当前文章的数据 | Filter out data that is not the current article
			let arr = topNewList?.data?.filter((item) => {
				return item?.id != props?.match?.params?.id;
			});
			setTopNews(arr);
		}
	}, [topNewList, props]);
	useEffect(() => {
		effect(MName, EGet, {
			modelName: InsightDetails?.title,
		});
	}, [InsightDetails?.title]);
	//从sessionStorage中获取当前页面和每页的项目数，并将页面减1。然后，它调用effect函数以获取新页面的数据 | Retrieve the current page and the number of items per page from sessionStorage, and subtract 1 from the page. Then, it calls the effect function to obtain the data for the new page
	const prev = () => {
		let page = sessionStorage.getItem("page");
		let perPage = sessionStorage.getItem("perPage");
		let index = sessionStorage.getItem("index");
		if (page == 1 && index == 0) {
			return;
		}
		if (page > 1 && index == 0) {
			sessionStorage.setItem("page", page - 1);
			effect(
				NInsightsFeed,
				EGet,
				{
					page: page - 1,
					pageSize: perPage,
				},
				(res) => {
					if (res) {
						sessionStorage.setItem("arr", JSON.stringify(res?.data));
						let arr = JSON.parse(sessionStorage.getItem("arr"));
						let id = arr[Number(perPage - 1)]?.id;
						sessionStorage.setItem("index", perPage - 1);
						history.push(`/news_articles/vana/details/${id}`);
					}
				}
			);
		} else {
			let arr = JSON.parse(sessionStorage.getItem("arr"));
			let id = arr[Number(index - 1)].id;
			sessionStorage.setItem("index", index - 1);
			history.push(`/news_articles/vana/details/${id}`);
		}
	};
	//从sessionStorage中获取当前页面和每页的项目数，并将页面加1。然后，它调用effect函数以获取新页面的数据 | Retrieve the current page and the number of items per page from sessionStorage, and add 1 to the page. Then, it calls the effect function to obtain the data for the new page
	const next = () => {
		let page = sessionStorage.getItem("page");
		let lastPage = sessionStorage.getItem("lastPage");
		let perPage = sessionStorage.getItem("perPage");
		let index = sessionStorage.getItem("index");
		if (page == lastPage && index == perPage - 1) {
			return;
		}
		if (page >= 1 && index == perPage - 1) {
			let page1 = Number(page) + 1;
			sessionStorage.setItem("page", page1);
			effect(
				NInsightsFeed,
				EGet,
				{
					page: page1,
					pageSize: perPage,
				},
				(res) => {
					if (res) {
						sessionStorage.setItem("arr", JSON.stringify(res?.data));
						let arr = JSON.parse(sessionStorage.getItem("arr"));
						let id = arr[Number(0)]?.id;
						sessionStorage.setItem("index", Number(0));
						sessionStorage.setItem("lastPage", res?.lastPage);
						history.push(`/news_articles/vana/details/${id}`);
					}
				}
			);
		} else {
			effect(
				NInsightsFeed,
				EGet,
				{
					page: page,
					pageSize: perPage,
				},
				(res) => {
					if (res) {
						let arr = JSON.parse(sessionStorage.getItem("arr"));
						let index2 = Number(index) + 1;
						let id = arr[index2].id;
						sessionStorage.setItem("index", index2);
						sessionStorage.setItem("lastPage", res.lastPage);
						history.push(`/news_articles/vana/details/${id}`);
					}
				}
			);
		}
	};
	const date = new Date(InsightDetails && InsightDetails?.createdAt);
	const formattedDate = formatDate(date);

	const copyVana = (e) => {
		e.preventDefault();
		if (InsightDetails?.content) {
			navigator.clipboard
				.writeText(InsightDetails?.content?.replace(/<[^>]+>/g, ""))
				.then(() => {
					// message.success("Content copied to clipboard!");
					console.log("Content copied to clipboard!");
				})
				.catch((err) => {
					// message.error("Failed to copy content!");
					console.error("Failed to copy: ", err);
				});
		}
	};

	const copyVanaTop = (e, vo) => {
		e.preventDefault();
		if (vo?.content) {
			navigator.clipboard
				.writeText(vo?.content?.replace(/<[^>]+>/g, ""))
				.then(() => {
					console.log("Content copied to clipboard!");
				})
				.catch((err) => {
					console.error("Failed to copy: ", err);
				});
		}
	};

	return (
		<div className="newDetail">
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          				gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			<div className="newContainer">
				<div className="vanaDetails">
					<div className="title">{InsightDetails?.title}</div>
					<div className="">
						{InsightDetails?.withGames
							?.slice(0, showAllItems ? InsightDetails?.withGames?.length : 3)
							?.map((item) => (
								<span
									key={item?.id}
									className="gameTags"
									onClick={() => {
										history.push({
											pathname: "/news_articles/VANA",
											state: { game: item?.id },
										});
									}}
								>
									{item?.gameEn}
								</span>
							))}
						{InsightDetails?.withOrgList
							?.slice(0, showAllItems ? InsightDetails?.withOrgList?.length : 3)
							?.map((item) => (
								<span
									key={item?.id}
									className="companyTags"
									onClick={() => {
										history.push({
											pathname: "/news_articles/VANA",
											state: { company: item?.id },
										});
									}}
								>
									{item?.name}
								</span>
							))}
						{(InsightDetails?.withOrgList?.length > 3 ||
							InsightDetails?.withGames?.length > 3) && (
							<span className="show-all" onClick={toggleShowAllItems}>
								{showAllItems ? "Show less..." : "Show all..."}
							</span>
						)}
					</div>
					<div className="headerInfo">
						<div className="areaRegionBox">
							{InsightDetails?.area?.length > 0 && (
								<span
									className="link"
									onClick={() => {
										history.push({
											pathname: "/news_articles/VANA",
											state: { area: InsightDetails?.area },
										});
									}}
								>
									<img
										src={imgSource?.map_icon}
										style={{ marginRight: "5px", width: "24px" }}
									></img>
									{InsightDetails?.area?.length > 0 &&
										InsightDetails?.area?.join(", ")}
								</span>
							)}
							{InsightDetails?.region?.length > 0 && (
								<span
									className="link"
									onClick={() => {
										history.push({
											pathname: "/news_articles/VANA",
											state: { region: InsightDetails?.region },
										});
									}}
								>
									<img
										src={imgSource?.globe_gray_icon}
										style={{ marginRight: "5px", width: "24px" }}
									></img>
									{InsightDetails?.region?.length > 0 &&
										InsightDetails?.region?.join(", ")}
								</span>
							)}
							{InsightDetails?.type && (
								<span
									className="link"
									onClick={() => {
										history.push({
											pathname: "/news_articles/VANA",
											state: {
												type: InsightDetails?.type,
												subType: InsightDetails?.subType,
											},
										});
									}}
								>
									<img
										src={imgSource?.folder_icon}
										style={{ marginRight: "5px", width: "24px" }}
									></img>
									{InsightDetails?.type}
								</span>
							)}
						</div>
						<div>
							<span>{formattedDate}</span>
						</div>
					</div>
					<div
						className="contentBox"
						dangerouslySetInnerHTML={{
							__html: InsightDetails?.content,
						}}
					></div>
					<div className="footerInfo ">
						<div className="link">
							<a
								href={InsightDetails?.link}
								target="_blank"
								onClick={copyVana}
								style={{ marginRight: "2rem" }}
							>
								<img
									src={imgSource?.copy_icon}
									style={{ marginRight: "5px", width: "24px" }}
								></img>
								<span>Copy VANA</span>
							</a>
							{InsightDetails?.link && (
								<a href={InsightDetails?.link} target="_blank">
									<img
										src={imgSource?.external_link_icon}
										style={{ marginRight: "5px", width: "24px" }}
									></img>
									<span>Visit Website</span>
								</a>
							)}
						</div>
						<div className="breaker"></div>
						<div className="btns">
							<div className="btnIcon" onClick={prev}>
								<img src={imgSource?.prev_icon}></img>
							</div>
							<div
								className="btnIcon"
								onClick={next}
								style={{ marginLeft: "1rem" }}
							>
								<img src={imgSource?.next_icon}></img>
							</div>
						</div>
					</div>
				</div>

				<div className="topNews">
					<div className="title">Recommended News</div>
					{topNews?.slice(0, 3)?.map((item, index) => {
						const date = new Date(item?.date);
						const formattedDate = formatDate(date);
						const showAll = showAllTags[index] || false;
						return (
							<>
								<div className="newsItem" key={index}>
									<div
										className="head"
										onClick={() => {
											history.push(`/news_articles/vana/details/${item?.id}`);
											window.scroll(0, 0);
										}}
									>
										<span>{item?.title}</span>
										<span className="topDate">{formattedDate}</span>
									</div>
									<div className="areaRegionBox">
										{item?.area?.length > 0 && (
											<span
												className="listLink"
												onClick={() => {
													history.push({
														pathname: "/news_articles/VANA",
														state: { area: item?.area },
													});
												}}
											>
												<img
													src={imgSource?.map_icon}
													style={{ marginRight: "5px", width: "24px" }}
												></img>
												{item?.area?.length > 0 && item?.area?.join(", ")}
											</span>
										)}
										{item?.region?.length > 0 && (
											<span
												className="listLink"
												onClick={() => {
													history.push({
														pathname: "/news_articles/VANA",
														state: { region: item?.region },
													});
												}}
											>
												<img
													src={imgSource?.globe_gray_icon}
													style={{ marginRight: "5px", width: "24px" }}
												></img>
												{item?.region?.length > 0 && item?.region?.join(", ")}
											</span>
										)}
										{item?.type && (
											<span
												className="listLink"
												onClick={() => {
													history.push({
														pathname: "/news_articles/VANA",
														state: {
															type: item?.type,
															subType: item?.subType,
														},
													});
												}}
											>
												<img
													src={imgSource?.folder_icon}
													style={{ marginRight: "5px", width: "24px" }}
												></img>
												{item?.type}
											</span>
										)}
									</div>
									{item?.withGames
										?.slice(0, showAll ? item?.withGames?.length : 3)
										?.map((item) => (
											<span
												key={item?.id}
												className="gameTags"
												onClick={() => {
													history.push({
														pathname: "/news_articles/VANA",
														state: { game: item?.id },
													});
												}}
											>
												{item?.gameEn}
											</span>
										))}
									{item?.withOrgList
										?.slice(0, showAll ? item?.withOrgList?.length : 3)
										?.map((item) => (
											<span
												key={item?.id}
												className="companyTags"
												onClick={() => {
													history.push({
														pathname: "/news_articles/VANA",
														state: { company: item?.id },
													});
												}}
											>
												{item?.name}
											</span>
										))}
									{(item?.withOrgList?.length > 3 ||
										item?.withGames?.length > 3) && (
										<span
											className="show-all"
											onClick={() => toggleShowAll(index)}
										>
											{showAll ? "Show less..." : "Show all..."}
										</span>
									)}
									<div
										className="text"
										onClick={() => {
											history.push(`/news_articles/vana/details/${item?.id}`);
											window.scroll(0, 0);
										}}
									>
										{item?.content?.replace(/<[^>]+>/g, "")}
									</div>
									<div className="link mt20">
										<a
											href={item?.link}
											target="_blank"
											onClick={(e) => copyVanaTop(e, item)}
											style={{ marginRight: "2rem" }}
										>
											<img
												src={imgSource?.copy_icon}
												style={{ marginRight: "5px", width: "24px" }}
											></img>
											<span>Copy VANA</span>
										</a>
										<a href={item?.link} target="_blank">
											<img
												src={imgSource?.external_link_icon}
												style={{ marginRight: "5px", width: "24px" }}
											></img>
											<span>Visit Website</span>
										</a>
									</div>
								</div>
							</>
						);
					})}
				</div>

				{topNews?.length > 3 && (
					<div className="viewWrapper">
						<Button
							className="secReset moreBtn mt20"
							type="default"
							htmlType="button"
							onClick={() => {
								history.push({
									pathname: "/news_articles/vana",
								});
							}}
						>
							View more <img src={imgSource?.right_arrow_blue}></img>
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};
